import { transparentize } from '@theme-ui/color'

export const site = {
  margin: '0 auto',
  maxWidth: 864,
}

export const header = {
  // alignItems: 'center',
  // backgroundColor: 'background',
  // borderBottom: theme =>
  //   `${theme.borderWidths[1]}px solid ${theme.colors.surface}`,
  // display: 'flex',
  margin: '0 auto',
  maxWidth: 864,
  // justifyContent: 'space-between',
  // height: theme => `${theme.space[5]}px`,
  // // ml: [0, 0, 0, sidebarWidth],
  // ml: [0, 0, 0, 0],
  // overflow: 'hidden',
  // position: 'fixed',
  // p: theme => [`0px ${theme.space[3]}px`, `0px ${theme.space[4]}px`],
  p: (theme) => [
    `${theme.space[3]}px ${theme.space[3]}px 0 ${theme.space[3]}px`,
    `${theme.space[3]}px ${theme.space[4]}px 0 ${theme.space[4]}px`,
  ],
  // // width: ['100%', '100%', '100%', '100%'],
  // // width: ['100%', '100%', '100%', `calc(100% - ${sidebarWidth}px)`],
  // zIndex: 997,
  // borderBottom: (theme) => `2px dashed ${theme.colors.primary}`,
}

export const headerFirstLine = {
  display: 'flex',
  justifyContent: 'space-between',
  '::after': {
    content: '""',
    background: (theme) =>
      `repeating-linear-gradient(90deg, ${theme.colors.primary}, ${theme.colors.primary} 2px, transparent 0, transparent 16px)`,
    display: 'block',
    width: '100%',
    right: '10px',
  },
}

export const headerName = {
  display: 'flex',
  flex: '0 0 auto',
  maxWidth: '100%',
  px: 2,
  py: 1,
  textTransform: 'lowercase',
  backgroundColor: (theme) => theme.colors.primary,
  color: (theme) => theme.colors.background,
}


export const left = {
  // alignItems: 'center',
  // display: ['flex', 'flex', 'flex', 'none'],
}
export const right = {
  // alignItems: 'center',
  // display: ['flex', 'flex', 'flex', 'none'],
  // flexBasis: '100%',
  // justifyContent: 'flex-end',
}

export const sidebar = ({ sidebarWidth, isNavOpen }) => ({
  backgroundColor: 'background',
  height: '100%',
  left: [
    isNavOpen ? '0px' : `-${sidebarWidth}px`,
    isNavOpen ? '0px' : `-${sidebarWidth}px`,
    isNavOpen ? '0px' : `-${sidebarWidth}px`,
    '0px',
  ],
  position: 'fixed',
  transition: '.3s ease-in-out left',
  width: sidebarWidth,
  zIndex: 999,
})

export const sidebarChild = ({ sidebarWidth, isNavOpen }) => ({
  borderRight: (theme) =>
    `${theme.borderWidths[1]}px solid ${theme.colors.surface}`,
  height: '100%',
  left: [
    `${isNavOpen ? 0 : `-${sidebarWidth}px`}`,
    `${isNavOpen ? 0 : `-${sidebarWidth}px`}`,
    `${isNavOpen ? 0 : `-${sidebarWidth}px`}`,
    0,
  ],
  transition: '.3s ease-in-out left',
  position: 'relative',
})

export const lightbox = ({ isNavOpen }) => ({
  backgroundColor: transparentize('black', 0.2),
  display: [
    isNavOpen ? 'flex' : 'none',
    isNavOpen ? 'flex' : 'none',
    isNavOpen ? 'flex' : 'none',
    'none',
  ],
  height: '100%',
  justifyContent: 'flex-end',
  p: (theme) => [
    `${theme.space[2]}px ${theme.space[3]}px`,
    `${theme.space[2]}px ${theme.space[4]}px`,
  ],
  position: 'fixed',
  transition: '.2s linear background-color',
  width: '100%',
  zIndex: 998,
  ':focus': {
    backgroundColor: transparentize('black', 0.4),
  },
})

export const main = ({ sidebarWidth }) => ({
  display: 'block',
  // ml: [0, 0, 0, sidebarWidth],
  ml: [0, 0, 0, 0],
  p: (theme) => [
    `${theme.space[4]}px ${theme.space[3]}px`,
    `${theme.space[4]}px ${theme.space[4]}px`,
  ],
  transition: '.3s ease-in-out margin-left',
})

/** @jsx jsx */
import { MDXProvider } from '@mdx-js/react'
import * as themeUiComponents from '@theme-ui/components'
// Either used by Main component or components prop
import { Heading, Image, Text } from '@theme-ui/components'
import Prism from '@theme-ui/prism'
import PropTypes from 'prop-types'
import { Fragment, useContext } from 'react'
import { components as themeUiMDXComponents, jsx } from 'theme-ui'
import { Context } from '../../context'
import { useConfig } from '../../data'
// Theme specific componenbts
import { Logo } from '../Logo'
import { Nav } from '../Nav'
import { SiteMetaData } from '../SiteMetaData'
import { SourceDays } from '../SourceDays'
import { SourceList } from '../SourceList'
import { SourceMonths } from '../SourceMonths'
import { SourceTags } from '../SourceTags'
import { SourceWords } from '../SourceWords'
import * as styles from './styles'

const components = {
  ...themeUiMDXComponents,
  pre: ({ children }) => <Fragment>{children}</Fragment>,
  code: Prism,
  Fragment,
  SiteMetaData,
  SourceList,
  SourceDays,
  SourceMonths,
  SourceWords,
  SourceTags,
  EmbeddedImage: ({ src, ...rest }) => <Image src={src.fluid.src} {...rest} />,
  ...themeUiComponents,
}
export const Main = ({ children }) => {
  const {
    site: {
      siteMetadata: {
        config: { sidebarWidth },
      },
    },
  } = useConfig()
  const {
    state: { isNavOpen },
    dispatch,
  } = useContext(Context)
  return (
    <Fragment>
      {/* <header sx={styles.header({ sidebarWidth })}> */}
      <header sx={styles.header}>

        <div sx={styles.headerFirstLine}>
          {/* <Logo sx={{ mr: 2, pt: '5px'}} /> */}
          <div sx={styles.headerName}>
            
            Geekish Stuff
          </div>
        </div>

        <Nav />
      </header>
      <div sx={styles.site}>
        {/* <div sx={styles.sidebar({ sidebarWidth, isNavOpen })}>
          <div sx={styles.sidebarChild({ sidebarWidth, isNavOpen })}>
            <Nav />
          </div>
        </div>
        <div
          role="button"
          tabIndex="0"
          sx={styles.lightbox({ isNavOpen })}
          onClick={() => dispatch({ type: 'closeNav' })}
          onKeyDown={(event) =>
            event.key === 'Enter' ? dispatch({ type: 'closeNav' }) : {}
          }
        >
          <Close />
        </div> */}

        <MDXProvider components={components}>
          <main sx={styles.main({ sidebarWidth })}>{children}</main>
        </MDXProvider>
      </div>
    </Fragment>
  )
}

Main.propTypes = {
  /** React children */
  children: PropTypes.any,
}
